import React from 'react'
import { Route, Routes, Navigate, Outlet } from 'react-router-dom'


import Main from '../pages/main'



export default function Router(): JSX.Element {
  return (
    <Routes>


      <Route path="/main" element={<Main />} />
      <Route path="*" element={<Navigate to="/main" replace />} />

    </Routes>
  )
}


import { WebAuthn } from '@dfns/sdk-webauthn'
import {UserRegistrationChallenge} from "@dfns/sdk";
import {AllowCredential} from "@dfns/sdk/signer";
const asUrl = (path: string): URL => new URL(path, process.env.REACT_APP_API_URL!)
console.log(process.env.REACT_APP_DFNS_WEBAUTHN_RPID)
const get = async (path: string): Promise<any> => {
  const res = await fetch(asUrl(path), {
    method: 'GET',
    credentials: 'include',
  })
  return res.json()
}

const post = async (path: string, body: any): Promise<any> => {
  const res = await fetch(asUrl(path), {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'content-type': 'application/json',
    },
    credentials: 'include',
  })
  return res.status !== 204 ? res.json() : undefined
}

export const api = {
  async login(username: string, password: string): Promise<{ username: string }> {

    const webauthn = new WebAuthn( { rpId: process.env.REACT_APP_DFNS_WEBAUTHN_RPID! })
    const { challenge, network } = {
    "challenge":{
        "supportedCredentialKinds": [
            {
                "kind": "Fido2",
                "factor": "either",
                "requiresSecondFactor": false
            }
        ],
        "challenge": "b37313dc4cd703ce3dbd3a3726ce65b83e431bb0ba32634d1b32124a4036963a",
        "challengeIdentifier": "eyJ0eXAiOiJKV1QiLCJhbGciOiJFZERTQSJ9.eyJpc3MiOiJhdXRoLmRmbnMubmluamEiLCJhdWQiOiJkZm5zOmF1dGg6dXNlciIsInN1YiI6Im9yLTVyYTdpLTRhN29mLTg0Mjk4MjFmMTkwMDQ0aXEiLCJqdGkiOiJ1ai01dmZ0NS03bnJiMS05ajlwc29uaW1nZnFvdTZpIiwic2NvcGUiOiIiLCJwZXJtaXNzaW9ucyI6W10sImh0dHBzOi8vY3VzdG9tL3VzZXJuYW1lIjoid2FsbGV0Y3JlYXRpb25AdGVzdC5jb20iLCJodHRwczovL2N1c3RvbS9hcHBfbWV0YWRhdGEiOnsidXNlcklkIjoidXMtMmxldTctZmVkdTUtODUzOWphbTIxZHMzMHZrNSIsIm9yZ0lkIjoib3ItNXJhN2ktNGE3b2YtODQyOTgyMWYxOTAwNDRpcSIsInRva2VuS2luZCI6IlRlbXAiLCJjaGFsbGVuZ2UiOiJ7XCJwYXlsb2FkXCI6XCJ7XFxcIm5ldHdvcmtcXFwiOlxcXCJQb2x5Z29uTXVtYmFpXFxcIn1cIixcIm1ldGhvZFwiOlwiUE9TVFwiLFwicGF0aFwiOlwiL3dhbGxldHNcIixcInNlcnZlclwiOlwiYXBpLmRmbnMubmluamFcIixcInN1bW1hcnlcIjpcIkNyZWF0ZSB3YWxsZXQgb24gUG9seWdvbk11bWJhaS5cIixcIm5vbmNlXCI6XCJuby01cTJlNi0zYzc3OC04MXVyNTA0bXRoZTYyNzltXCJ9In0sImlhdCI6MTcwMjkwNTE0OCwiZXhwIjoxNzAyOTA2MDQ4fQ.FfDD1srn2I6xy4s5cGThFmJbnSWygm1MsIOdUdLv10PDFDLzOUZbw3O_3Jer-B_yjP2jDRx2D6Yu4W-5Pk_iBQ",
        "externalAuthenticationUrl": "euLxBYh8NmDQFOHaApt1qCYzstg",
        "allowCredentials": {
            "webauthn": [
                {
                    "type": "public-key",
                    "id": "Ftxlm-7au07tlDZ51LJDlg"
                }
            ],
            "key": []
        }
    },
    "network": "PolygonMumbai"
    }
    const assertion = await webauthn.sign(challenge.challenge,
        challenge.allowCredentials as unknown as {
          key: AllowCredential[];
          webauthn: AllowCredential[];
        })


    console.log(JSON.stringify({
      signedChallenge: { challengeIdentifier: challenge.challengeIdentifier, firstFactor: assertion },
      network,
    }))

    return { username: '' }
  },

  async register(username: string, password: string): Promise<{ username: string }> {
    const challenge =  {
        "rp": {
            "id": "openwallet-test.web.app",
            "name": "Dfns"
        },
        "user": {
            "id": "us-5hmf4-ucl59-8cv9q046pri9aelo",
            "displayName": "clientandroid+7@test.com (or-5ra7i-4a7of-8429821f190044iq",
            "name": "clientandroid+7@test.com"
        },
        "temporaryAuthenticationToken": "eyJ0eXAiOiJKV1QiLCJhbGciOiJFZERTQSJ9.eyJpc3MiOiJhdXRoLmRmbnMubmluamEiLCJhdWQiOiJkZm5zOmF1dGg6dXNlciIsInN1YiI6Im9yLTVyYTdpLTRhN29mLTg0Mjk4MjFmMTkwMDQ0aXEiLCJqdGkiOiJ1ai0xNjBhci1wM2RsYi04Y2xiMmdvNzEzajZ1dmF2Iiwic2NvcGUiOiIiLCJwZXJtaXNzaW9ucyI6W10sImh0dHBzOi8vY3VzdG9tL3VzZXJuYW1lIjoiY2xpZW50YW5kcm9pZCs3QHRlc3QuY29tIiwiaHR0cHM6Ly9jdXN0b20vYXBwX21ldGFkYXRhIjp7InVzZXJJZCI6InVzLTVobWY0LXVjbDU5LThjdjlxMDQ2cHJpOWFlbG8iLCJvcmdJZCI6Im9yLTVyYTdpLTRhN29mLTg0Mjk4MjFmMTkwMDQ0aXEiLCJ0b2tlbktpbmQiOiJDb2RlIiwiY2hhbGxlbmdlIjoie1wiY2hhbGxlbmdlXCI6XCJjaC0xa25tZy0wamdrdi05dXE4cDgwbnIzY2llZ2xnXCIsXCJjcmVkSW5mb1wiOlwiWGx1ZWZTWTBKOVhhSWNBL29ETTZ1SVdOUUZTUTVNbWV4SEVhRDlkbFQrSUI5L3EyK0RSR01EUW1MSTZBTCszZ3FYd0tYWGRmOTNlMTBvTVM3ejQ1S1FHa2REd1htcURrVUMwTllFY1JVRktJblJubmtpemJocHZ0RWNqVWNaRm5Ga1Y2Ymozb3ZiU2VrOERKRzZzazBwQ0JQTVZXTmV4VW8rYVZ3TW9vN01jWGFpV081SmJqeERiNmpDcGhkck14blBpRUUvd2RteTNzaGF1WHJYdHlnOGx2em9FTm5ndU1KQU9XZ3QxY3RzSzFqS0Rvb0FlSXkwWlJ1aXk2VWw1SERmTUtvc2lvdkNGYU5Gd3hwMU5XOEVnMkhkZWFWbEZsS3NnUHQzR2RDQU1kclU1YkdIWS90dnN6bTFVNDRTNWRpT0JpM1JNLzNjemQyMWlvd25YM0R3PT1cIn0ifSwiaWF0IjoxNzA1MDc1NTI3LCJleHAiOjE3MDUwNzY0Mjd9.8Wb8vt5vuwiQJInulzPH5kz962upc2X1fsIMC-N2lixynb4uxyyySnGWSxxlAjvqz0UpXxkSbsVEaQVj1iIPAA",
        "challenge": "ch-1knmg-0jgkv-9uq8p80nr3cieglg",
        "pubKeyCredParams": [
            {
                "type": "public-key",
                "alg": -257
            },
            {
                "type": "public-key",
                "alg": -7
            }
        ],
        "excludeCredentials": [],
        "authenticatorSelection": {
            "residentKey": "required",
            "requireResidentKey": true,
            "userVerification": "required"
        },
        "attestation": "direct",
        "supportedCredentialKinds": {
            "firstFactor": [
                "Fido2",
                "Key",
                "Password"
            ],
            "secondFactor": [
                "Fido2",
                "Key",
                "Totp"
            ]
        },
        "otpUrl": "otpauth://totp/dfns.ninja:clientandroid%2B7%40test.com?issuer=dfns.ninja&secret=TJAQ6BS5CIART546KDUYOIBSOPKSPYO447EEHEET7A4F6CTHF6LHCEPJF5MT2TDHRQPRX45VOKLI3H5Z3H3Z5B6Z2DXOYF7EONVH3UY&algorithm=SHA512&digits=6&period=30"
    }as UserRegistrationChallenge

    //await post('/register/init', { username, password })

console.log(process.env.REACT_APP_DFNS_WEBAUTHN_RPID! )
    const webauthn = new WebAuthn({ rpId: process.env.REACT_APP_DFNS_WEBAUTHN_RPID! })
    const attestation = await webauthn.create(challenge)

    console.log(JSON.stringify({
      firstFactorCredential: attestation,
      temporaryAuthenticationToken: challenge.temporaryAuthenticationToken,
    }))

    return { username: ''}
  },

  async listWallets() {
    return get('/wallets/list')
  },

  async createWallet(network: string) {
    const {
      requestBody,
      challenge: { challenge, challengeIdentifier, allowCredentials },
    } = await post('/wallets/new/init', { network })

    const webauthn = new WebAuthn({ rpId: process.env.REACT_APP_DFNS_WEBAUTHN_RPID! })
    const assertion = await webauthn.sign(challenge, allowCredentials)

    await post('/wallets/new/complete', {
      requestBody,
      signedChallenge: { challengeIdentifier, firstFactor: assertion },
    })
  },
}

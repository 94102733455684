import React, { useEffect } from 'react';

import '../globals.css';

export default function Login(): JSX.Element {

/*
  useEffect(() => {
    // Redirect to the specified URL
    window.location.href = 'https://openwallet.finance/';
  }, []);
 */

  return (
    <h1>https://openwallet.com</h1>
  );
}